import AOS from 'aos';

$(function () {
	// mobile nav controller
	$('.mobile-nav-trigger').on('click', function () {
		$(this).toggleClass('active');
		$('nav.nav').toggleClass('mobile-menu-open');
	});


	// search logic
	$('.search-btn-trigger').on('click', function () {
		$('.search-container').slideDown(500);
		$('.search-container input').focus();
		$('body').addClass('overflow-hidden');
	});

	$('.exit-search').on('click', function () {
		$('.search-container').slideUp(500);
		$('body').removeClass('overflow-hidden');
	});


	// accordion logic
	$('.a-header').on('click', function () {
		// if user clicked on an open accordion
		if ($(this).hasClass('open')) {
			$(this).removeClass('open');
			$(this).next().slideUp();
			$(this).find('i').removeClass('fa-minus').addClass('fa-plus');
		} else {
			// setup some vars to make life easy peezy
			var $thisH = $(this);
			var $thisB = $(this).next();
			var $thisI = $(this).find('i');

			// close all open accords
			$('.single-accordion .a-body').slideUp();
			$('.single-accordion .a-header').removeClass('open');
			$('.single-accordion .a-header i').removeClass('fa-minus').addClass('fa-plus');

			// open the clicked accord
			$thisB.slideDown();
			$thisH.addClass('open');
			$thisI.removeClass('fa-plus').addClass('fa-minus');
		}
	});
	// open the first accordion on load
	$('.a-header').eq(0).trigger('click');

	// sub-menu dropdowns
	const dropdown = document.querySelector(".menu-item-has-children");
	const subMenu = document.querySelector(".sub-menu");

	dropdown.addEventListener("click", function () {

		dropdown.classList.toggle("rotate");
		subMenu.classList.toggle("open");
	})

	// table scroll logic
    // wraps scrollable tables on medium and smaller viewports
    function scrollWrap() {
        $('table').wrap('<div class="scroll-wrapper"></div>');
        $('.scroll-wrapper').before('<div class="scroll-header">Swipe to view more content</div>');
    }
    scrollWrap();
    // show or hide table scroll header based on if overflowing its parent
    function handleScrollResize() {
        $('table').each(function (index) {
            var table = $(this);
            var tableWidth = table.outerWidth();
            var parentWidth = table.parent().outerWidth();
            var scrollHeader = table.parent().prevAll('.scroll-header:first');
            var scrollWrapper = table.parent('.scroll-wrapper');

            if (tableWidth > parentWidth) {
                // element has overflow
                scrollHeader.show();
                scrollWrapper.css('overflow-x', 'scroll');
            } else {
                // element doesn't overflow
                scrollHeader.hide();
                scrollWrapper.css('overflow-x', 'hidden');
            }
        })
    }
    $(window).on("resize", function () {
        handleScrollResize();
    });
    $(document).ready(function () {
        handleScrollResize();
    });

	// initialize AOS animation
	AOS.init();
});